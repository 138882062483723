.AppHeader {
    color: #ffffff;
    background-color: #44aaee;
    min-height: 10vh;
}

.navbar>.container {
    width: 100%;
    max-width: 100%;
}

.dropdown-menu {
    color: #ffffff;
    background-color: #44aaee;
}

.dropdown-item, .navbar-light .navbar-nav .nav-link {
    color: #ffffff;
    background-color: #44aaee;
}

.dropdown-item:focus, .dropdown-item:hover, .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #44aaee;
    background-color: #ffffff;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    font-weight: bold;
    color: #ffffff;
}
